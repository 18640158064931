import React from 'react';
import { Link } from "react-router-dom";

export default function Home() {
    return (
      <div>
        <div className="row">
          <div className="col-lg-7 mb-3 aims-scope-frame">
            
            <h2>Aims & Scope</h2>
            <p>
              Bulletin of Materials Science and Metallurgy is a multidisciplinary
              journal which is devoted to publishing high-quality experimental
              research, technical evaluations, and reviews within the various
              fields of Materials Science and Metallurgy. BMSM aims to provide a
              high-profile, leading-edge forum for academic researchers,
              industrial professionals, engineers, educators and policy makers
              working in the field of Materials Science and Metallurgy.
            </p>

            <p>
              The owner of the Bulletin of Materials Science and Metallurgy is the
              Union of Chambers of Turkish Engineers and Architects (UCTEA)
              Chamber of Metallurgical and Materials Engineering.
            </p>
            <div className="col-md-6">
            <Link to="/issues/2">
              <div className="btn-spesific-lg mt-3">
                &raquo; Latest Issue<br />
                &nbsp;&nbsp; 2024 - Volume 1 - Issue 2
              </div>
            </Link>
            </div>
          </div>
          <div className="col-lg-5 mb-3">
            <h2>Sections</h2>

            <ul id="sections-list">
              <li>
                <Link className='bm-link' to="/editorial-board">
                  <i className="fas fa-user-friends"></i> <span>Editorial Board</span>
                </Link>
              </li>
              <li>
                <Link to="/abstracting-indexing">
                  <i className="fas fa-indent"></i>
                  <span>Abstracting & Indexing</span>
                </Link>
              </li>
              <li>
                <Link to="/guide-for-authors">
                  <i className="fas fa-book"></i> <span>Guide for Authors</span>
                </Link>
              </li>
              <li>
                <Link to="/submit-your-article">
                  <i className="fas fa-clipboard-check"></i>
                  <span>Submit Your Article</span>
                </Link>
              </li>
              <li>
                <Link to="/previous-issues">
                  <i className="fas fa-search"></i>
                  <span>Previous Issues</span>
                </Link>
              </li>
              <li>
                <Link to="/news">
                  <i className="far fa-newspaper"></i>
                  <span>News</span>
                </Link>
              </li>
              <li>
                <Link to="/announcements">
                  <i className="fas fa-bullhorn"></i>
                  <span>Announcements</span>
                </Link>
              </li>
              <li>
                <Link to="/journal-metrics">
                  <i className="fas fa-newspaper"></i>
                  <span>Journal Metrics</span>
                </Link>
              </li>
              <li>
                <Link to="/volunteer-to-review">
                  <i className="fas fa-hands-helping"></i>
                  <span>Volunteer to Review</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
}