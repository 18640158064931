import React, { useEffect } from 'react';
import { useLocation, Link, Route, Routes } from "react-router-dom";
import routes from './routes.js';

function App(props) {
  const location = useLocation();

  useEffect(() => {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
  }, [location]);

  return (
    <>
    <header id="header">
          <div className="container">
              <div className="row header-top-info">
                  <div className="col">
                      <span className="web-page-text"><i className="fas fa-globe"></i> www.bulletinofmsm.org </span><i className="far fa-envelope"></i> info@bulletinofmsm.org <i className="fas fa-phone"></i> +90 (312) 425 4160
                  </div>
              </div>
              <div className="header-inner">
                  <div className="d-flex justify-content-between">
                      <div className="header-left">
                          <div id="logo">
                              <Link className="navbar-brand" to="/">
                                  <img src="/assets/img/bmsm-logo.png" alt="" title="" />
                              </Link>
                          </div>
                      </div>
                      <div className="header-right">
                          <div id="page-top-title">
                              <div className="">
                                  <div className="d-flex align-items-center justify-content-end top-large">BULLETIN OF MATERIALS SCIENCE AND METALLURGY</div>
                                  <div className="d-flex align-items-center justify-content-end top-small">
                                      Periodical Scientific Journal of <a href="https://www.metalurji.org.tr" target="_blank" rel="noreferrer">UCTEA Chamber of Metallurgical and Materials Engineers</a>
                                  </div>
                              </div>
                          </div>
                          <div id="mainMenu" className="d-flex justify-content-end">
                              <nav className="navbar navbar-expand-sm navbar-toggleable-sm navbar-light box-shadow">
                                  <div className="container justify-content-end">
                                      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target=".navbar-collapse" aria-controls="navbarSupportedContent"
                                              aria-expanded="false" aria-label="Toggle navigation">
                                          <span className="navbar-toggler-icon"></span>
                                      </button>
                                      <div className="navbar-collapse collapse d-sm-inline-flex justify-content-between">
                                          <ul className="navbar-nav flex-grow-1">
                                              <li className="nav-item">
                                                  <Link className="nav-link text-dark" to="/">Home</Link>
                                              </li>
                                              <li className="nav-item dropdown">
                                                  <Link className="nav-link text-dark dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false">Pages</Link>
                                                  <ul className="dropdown-menu">
                                                      <li><Link className="dropdown-item" to="/editorial-board">Editorial Board</Link></li>
                                                      <li><Link className="dropdown-item" to="/technical-editors">Technical Editors</Link></li>
                                                      <li><Link className="dropdown-item" to="/advisory-board">Advisory Board</Link></li>
                                                      <li><Link className="dropdown-item" to="/about-journal">About Journal</Link></li>
                                                      <li><Link className="dropdown-item" to="/abstracting-indexing">Abstracting & Indexing</Link></li>
                                                      <li><Link className="dropdown-item" to="/guide-for-authors">Guide for Authors</Link></li>
                                                      <li><Link className="dropdown-item" to="/submit-your-article">Submit Your Article</Link></li>
                                                      <li><Link className="dropdown-item" to="/previous-issues">Previous Issues</Link></li>
                                                      <li><Link className="dropdown-item" to="/journal-metrics">Journal Metrics</Link></li>
                                                      <li><Link className="dropdown-item" to="/volunteer-to-review">Volunteer to Review</Link></li>
                                                      <li><hr className="dropdown-divider" /></li>
                                                      <li><Link className="dropdown-item" to="/news">News</Link></li>
                                                      <li><Link className="dropdown-item" to="/announcements">Announcements</Link></li>
                                                  </ul>
                                              </li>
                                              <li className="nav-item">
                                                  <Link className="nav-link text-dark" to="/news">News</Link>
                                              </li>
                                              <li className="nav-item">
                                                  <Link className="nav-link text-dark" to="announcements">Announcements</Link>
                                              </li>
                                              <li className="nav-item">
                                                  <Link className="nav-link text-dark" to="contact-us">Contact Us</Link>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </nav>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </header>
      <div className="container">
          <main role="main" className="divMain">
            <div className="text-right mb-4"><b><i className="fas fa-caret-right me-2 ms-2"></i>Online ISSN: 3023-6509</b></div>
            <Routes>
              {routes.map((n, i) => (
                  <Route
                    path={n.layout + n.path}
                    element={n.component}
                    caseSensitive={false}
                    key={i}
                  />
              ))}
            </Routes>
          </main>
      </div>
    </>
  );
}

export default App;