import { Link } from "react-router-dom";

function PreviousIssues() {
  return (
    <>
      <h2>Previous Issues</h2>
      <ul className="previous-issues-list li-arrow-anim">
        <li>
          <Link to="/issues/1">
            January 2024 - Volume 1 - Issue 1
          </Link>
        </li>
        <li>
          <Link to="/issues/2">
            May 2024 - Volume 1 - Issue 2
          </Link>
        </li>
      </ul>
    </>
  );
}

export default PreviousIssues;
