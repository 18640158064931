//import logo from './logo.svg';
//import '../assets/App.css';

function App() {
  return (
    <>
    <h2>Volunteer to Review</h2>

<p>Volunteer to review papers for Bulletin of Materials Science and Metallurgy.</p>
<p>Researchers interested in reviewing for Bulletin of Materials Science and Metallurgy can now volunteer to be considered as a peer reviewer by filling out a simple online form.</p>
<p>Once you register, including providing a list of your latest publications, area of expertise, and your motivation for volunteering as a peer reviewer, editors will receive a notification of interest. It is then up to the individual journal editors to contact volunteer reviewers in order to request reviewers. </p>
<p>Register to be a volunteer reviewer.</p>
<ul id="sections-list">
    <li><a href="/files/Volunteer_to_Review.docx" target="_blank"><i className="fas fa-file-download"></i> <span>Volunteer to Review</span></a></li>
</ul>
    </>
  );
}

export default App;
