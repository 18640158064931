import React from 'react';
import jsonData from '../docs/technical-editors.json';


export default function EditorialBoard() {
    let json = jsonData.sort((a,b) => {
        if (a.Name < b.Name) return -1;
        return 1;
    });

    return (
        <>
        <h2>Technical Editors</h2>
    
        <div className="mt-5 text-center board-table">
                {json.map((n, i) => {
                return (
                    <div key={i}>
                        <div><b>{n.Name}</b></div>
                        <div>{n.Title}</div>
                        <div className="board-list-seperator"><i className="fas fa-grip-lines"></i></div>
                    </div>
                );
                })}
        </div>
        </>
    )
}