//import logo from './logo.svg';
//import '../assets/App.css';

function SubmitYourArticle() {
  return (
    <>
    <h2>Submit Your Article</h2>


<p>Please submit your article with a cover letter and the table of contents (ToC) entry to the following address:</p>
<p><a className="c-primary" href="mailto:submission@bulletinofmsm.org">submission@bulletinofmsm.org</a></p>
    </>
  );
}

export default SubmitYourArticle;