function ContactUs() {
  return (
    <>
    <h2>Contact Us</h2>

<div className="row">
    <div className="col-lg-6 single-contact">
        <i className="fas fa-map-marker-alt"></i>
        <div className="contact-content">
            <h6>Postal Address</h6>
            Bulletin of Materials Science and Metallurgy,<br />
            TMMOB Metalurji ve Malzeme Mühendisleri Odası,<br />
            Hatay Sok., No: 10/9, Kızılay, 06420, Ankara, Turkey
        </div>
    </div>
    <div className="col-lg-6 single-contact">
        <i className="fas fa-phone"></i>
        <div className="contact-content">
            <h6>Phone</h6>
            +90 (312) 425 41 60<br />
            +90 (312) 419 38 18
        </div>
    </div>
    <div className="col-lg-6 single-contact">
        <i className="fas fa-fax"></i>
        <div className="contact-content">
            <h6>Fax</h6>
            +90 (312) 418 93 43
        </div>
    </div>
    <div className="col-lg-6 single-contact">
        <i className="fas fa-envelope"></i>
        <div className="contact-content">
            <h6>E-Mail</h6>
            info@bulletinofmsm.org
        </div>
    </div>
</div>
    </>
  );
}

export default ContactUs;
