function AboutJournal() {
  return (
    <>
    <h2>About Journal</h2>

<div className="row">
    <div className="col-lg-12">
        <ul className="about-journal-list">
            <li>This journal will be published 3 times a year in January, May and September.</li>
            <li>The recommendations of ICMJE (International Committee of Medical Journal Editors) and the International Standards for Editors and Authors of COPE (Committee on Publication Ethics) are taken into consideration in the scientific articles submitted to our journal.</li>
            <li>Manuscripts submitted to our journal are first checked for template and scope and then forwarded to the referees for evaluation of the scientific aspect. As a result of the reviewers' evaluation, the submitted articles are either rejected, accepted or returned to the corresponding author for minor or major revisions. After the revision process is completed, the manuscripts are sent to the referees again and the manuscripts that are deemed to be suitable for publication as a result of the referee evaluation are published in the journal.</li>
            <li>Articles published in our journal are open access and no publication fee is charged to the authors.</li>
        </ul>
    </div>
</div>
    </>
  );
}

export default AboutJournal;
