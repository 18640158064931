import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
//import Moment from 'moment';
import "moment/locale/tr";

export default function PreviousIssuesWithId(prop) {
  const params = useParams();
  const [Article, setArticle] = useState();
  const [Issue, setIssue] = useState();

  useEffect(() => {
    const fetchData = async () => {
      let id = params.id;
      console.log(id);

      let arrIssueFiles = ["Issue20240101", "Issue20240501"];

      arrIssueFiles.forEach(async (element) => {
        const response = await fetch(`/docs/${element}.json`);
        const json = await response.json();
        json.Articles.forEach((e) => {
          console.log(e);
          if (id == e.Id) {
            setArticle(e);
            setIssue(json);
          }
        });
      });
    };
    console.log("step 1");
    fetchData();
  }, []);

  //let dt = Moment(articles.PublishDate).format('L');
  return (
    <>
      <div class="mb-5"><b>Issue:</b><br /><span style={{textDecoration:"underline"}}><a href={"/issues/" + (Issue ? Issue.Id : "") }>{Issue ? Issue.Title : ""}</a></span></div>
      
      {Article ? <MyArticle Article={Article} Issue={Issue} /> : null}
    </>
  );
}

function MyArticle(prop) {
  let article = prop.Article;
  let issue = prop.Issue;
  return (
    <>
      <h2>{article.Type}</h2>
      <div className="mb-5">
        <h4 style={{fontWeight:"bold"}}>{article.Title}</h4>
        <div>{article.Names}</div>
        <div class="mt-3 mb-3"><b>Abstract</b></div>
        <div>{article.Abstract}</div>
        <div class="mt-3 mb-3"><b>Keywords :</b> {article.Keywords}</div>
        <div class="mt-3 mb-3">
          <a
            href={issue.Folder + article.FileLink}
            rel="noreferrer"
            target="_blank"
          >
            <div class="btn-spesific-lg mt-3">
            <i className="far fa-file-pdf fa-x2 pdf-icon"></i> PDF
            </div>
          </a>
        </div>
      </div>
    </>
  );
}