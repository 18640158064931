import json from '../docs/advisory-board.json';

function AdvisoryBoard() {
  return (
    <>
    <h2>Advisory Board</h2>

<div className="mt-5 text-center board-table">
        {json.map((n, i) => {
          return (
            <div key={i}>
                <div><b>{n.Name}</b></div>
                <div>{n.Title}</div>
                <div className="board-list-seperator"><i className="fas fa-grip-lines"></i></div>
            </div>
          );
        })}
</div>
    </>
  );
}

export default AdvisoryBoard;
