import Home from '../views/Home.js';
import AbstractingIndexing from '../views/AbstractingIndexing.js';
import AdvisoryBoard from '../views/AdvisoryBoard.js';
import Announcements from '../views/Announcements.js';
import ContactUs from '../views/ContactUs.js';
import EditorialBoard from '../views/EditorialBoard.js';
import GuideForAuthors from '../views/GuideForAuthors.js';
import JournalMetrics from '../views/JournalMetrics.js';
import News from '../views/News.js';
import PreviousIssues from '../views/PreviousIssues.js';
import PreviousIssuesWithId from '../views/PreviousIssuesWithId.js';
import ArticleViewer from '../views/ArticleViewer.js';
import SubmitYourArticle from '../views/SubmitYourArticle.js';
import TechnicalEditors from '../views/TechnicalEditors.js';
import VolunteerToReview from '../views/VolunteerToReview.js';
import AboutJournal from '../views/AboutJournal.js';
import { Navigate } from "react-router-dom";

var routes = [
    {
        path: "/",
        name: "Home",
        icon: "ni ni-tv-2 text-primary",
        component: <Home />,
        layout: "",
    },
    {
        path: "/about-journal",
        name: "AboutJournal",
        icon: "ni ni-tv-2 text-primary",
        component: <AboutJournal />,
        layout: "",
    },
    {
        path: "/advisory-board",
        name: "AdvisoryBoard",
        icon: "ni ni-tv-2 text-primary",
        component: <AdvisoryBoard />,
        layout: "",
    },
    {
        path: "/abstracting-indexing",
        name: "AbstractingIndexing",
        icon: "ni ni-tv-2 text-primary",
        component: <AbstractingIndexing />,
        layout: "",
    },
    {
        path: "/announcements",
        name: "Announcements",
        icon: "ni ni-tv-2 text-primary",
        component: <Announcements />,
        layout: "",
    },
    {
        path: "/contact-us",
        name: "ContactUs",
        icon: "ni ni-tv-2 text-primary",
        component: <ContactUs />,
        layout: "",
    },
    {
        path: "/editorial-board",
        name: "EditorialBoard",
        icon: "ni ni-tv-2 text-primary",
        component: <EditorialBoard />,
        layout: "",
    },
    {
        path: "/guide-for-authors",
        name: "GuideForAuthors",
        icon: "ni ni-tv-2 text-primary",
        component: <GuideForAuthors />,
        layout: "",
    },
    {
        path: "/journal-metrics",
        name: "JournalMetrics",
        icon: "ni ni-tv-2 text-primary",
        component: <JournalMetrics />,
        layout: "",
    },
    {
        path: "/news",
        name: "News",
        icon: "ni ni-tv-2 text-primary",
        component: <News />,
        layout: "",
    },
    {
        path: "/previous-issues",
        name: "PreviousIssues",
        icon: "ni ni-tv-2 text-primary",
        component: <PreviousIssues />,
        layout: "",
    },
    {
        path: "/issues/:id/:title?",
        name: "PreviousIssuesWithId",
        icon: "ni ni-tv-2 text-primary",
        component: <PreviousIssuesWithId />,
        layout: "",
    },
    {
        path: "/issues/article/:id/:title?",
        name: "ArticleViewer",
        icon: "ni ni-tv-2 text-primary",
        component: <ArticleViewer />,
        layout: "",
    },
    {
        path: "/submit-your-article",
        name: "SubmitYourArticle",
        icon: "ni ni-tv-2 text-primary",
        component: <SubmitYourArticle />,
        layout: "",
    },
    {
        path: "/technical-editors",
        name: "TechnicalEditors",
        icon: "ni ni-tv-2 text-primary",
        component: <TechnicalEditors />,
        layout: "",
    },
    {
        path: "/volunteer-to-review",
        name: "VolunteerToReview",
        icon: "ni ni-tv-2 text-primary",
        component: <VolunteerToReview />,
        layout: "",
    },
    {
        path: "*",
        name: "Mismatch",
        icon: "",
        component: <Navigate to="/" />,
        layout: "",
    }
]

export default routes;