import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
//import Moment from 'moment';
import "moment/locale/tr";

export default function PreviousIssuesWithId(prop) {
  const params = useParams();
  const [Issue, setIssue] = useState();
  
  useEffect(() => {
    const fetchData = async () => {
      let id = params.id;
      let file;
      if (id == 1) {
        file = "Issue20240101";
      } else if (id == 2) {
        file = "Issue20240501";
      }
      const response = await fetch(`/docs/${file}.json`);
      const json = await response.json();
      setIssue(json);
    };
    console.log("step 1");
    fetchData();
  }, []);

  //let dt = Moment(articles.PublishDate).format('L');
  return (
    <>
      {Issue ? <Deneme Magazine={Issue} /> : null}
    </>
  );
}

const renderHTML = (rawHTML) =>
  React.createElement("span", {
    dangerouslySetInnerHTML: { __html: rawHTML },
  });

function Deneme(prop) {
  let magazine = prop.Magazine;
  return (
    <>
      <div className="mb-3 ms-2">
        <Link to="/previous-issues">
          <i className="far fa-caret-square-left me-2"></i>Issues Index
        </Link>
      </div>
      <div className="issue-detail-title d-flex justify-content-between">
        <div className="d-flex">{magazine.Title}</div>
        <div className="d-flex">{magazine.PublishDate}</div>
      </div>
      <div>
        <a
          href={magazine.Folder + magazine.CoverPdf}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={magazine.Folder + magazine.CoverImage}
            style={{ width: "25%" }}
            alt={magazine.Title}
          />
        </a>
      </div>
      <div className="mb-5">
        <ul className="previous-issues-list li-arrow-anim">
          <li>
            <a
              href={magazine.Folder + magazine.CoverPdf}
              target="_blank"
              rel="noreferrer"
            >
              Cover Image
            </a>
          </li>
          <li>
            <a
              href={magazine.Folder + magazine.EditorialBoardPdf}
              target="_blank"
              rel="noreferrer"
            >
              Editorial Board
            </a>
          </li>
          <li>
            <a
              href={magazine.Folder + magazine.ContentsPdf}
              target="_blank"
              rel="noreferrer"
            >
              Contents
            </a>
          </li>
        </ul>
      </div>

      <ul className="issue-article-list">
        {magazine
          ? magazine.Articles.map((a, i) => {
              return (
                <li key={i}>
                  <div className="article-type">{a.Type}</div>
                  <h5>
                    <a href={"/issues/article/" + a.Id}>
                      <i className="fas fa-angle-right me-3"></i>
                      {renderHTML(a.Title)}
                    </a>
                  </h5>
                  <p>{a.Names}</p>
                  <p>{a.PageInfo}</p>
                  <p>
                    <a
                      href={magazine.Folder + a.FileLink}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i className="far fa-file-pdf fa-x2 pdf-icon"></i>
                    </a>
                  </p>
                </li>
              );
            })
          : null}
      </ul>
    </>
  );
}
