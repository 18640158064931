import React from 'react';
import jsonData from '../docs/editorial-board.json';


export default function EditorialBoard() {
    let arrTitles = [ "Owner", "Editor-in-Chief", "Associate Editors", "Editorial Board" ];
    let json = jsonData.sort((a,b) => { 
        if (a.Name < b.Name) return -1;
        return 1;
    });

    return (
        <>
        <h2>Editorial Board</h2>

        <div className="mt-5 text-center board-table">
        {arrTitles.map((t,i) => {
            return (
                <div key={i}>
                <div className="board-job-title">{t}</div>
                {json.map((n,j) => {
                    if (n.Job === t) {
                        return (
                            <div key={1000+j}>
                                <div><b>{n.Name}</b></div>
                                <div>{n.Title}</div>
                                <div className="board-list-seperator">&nbsp;</div>
                            </div>
                        )
                    }
                    else
                        return null;
                })}
                <div className="board-seperator"><i className="fas fa-grip-lines"></i></div>
                </div>
            )
        })}
        </div>
        </>
    )
}