function GuideForAuthors() {
  return (
    <>
    <h2>Guide for Authors</h2>


<p>You should submit a set of files containing your cover letter, table of contents (ToC) entry and manuscript.</p>
<p>Figure and table lists are not mandatory to submit but, they should be submitted in cases which are defined in the guide below.</p>
<p>Following format is used for the publication of articles in BMSM.</p>

<ul id="sections-list">
    <li><a href="/files/BMSM_ToC_Template.docx" target="_blank"><i className="fas fa-file-download"></i> <span>BMSM ToC Template</span></a></li>
    <li><a href="/files/BMSM_Guide_for_Authors.docx" target="_blank"><i className="fas fa-file-download"></i> <span>Guide for Authors</span></a></li>
</ul>
    </>
  );
}

export default GuideForAuthors;
